@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.main, body, #root{
  /*background:linear-gradient(90deg, rgba(77,77,80,1) 100%, rgba(0,0,0,1) 100%); min-height: 80vh; */
  background:#efefef;
}
.mainbanner{
  width: 100%;
  padding:0;
  margin-bottom:1em;
  height:auto;
}
.div_main{
  background-color: #fff;
}
.home-page{
  max-width:1200px;
  margin:0 auto;
  background-color: #fff;
  padding-bottom: 3em;
  box-shadow: 1px 2px 0 rgba(0,0,0,0.24);
}
.embed-page{
  max-width:720px;
  margin:0 auto;
  padding:0;
  background-color: #fff;
  padding: 0.5em 0 0 0;
  box-sizing: border-box;
}
.embed_title{
  font-size: 1.3em;
  color:#666;
  margin: 0 auto 0.5em auto;
  text-align: center;
  letter-spacing: 1px;
  font-weight: bold;
}
[id^="div-gpt-ad-"] {
    text-align:center;
    margin: 0 auto;
}
.state_name{
  text-align: center;
  font-weight: bold;
  font-size:1.3em;
  width: 100%;
  display: block;
  margin:0 auto 0 auto;
}
.state_name a{
  text-decoration: underline;
  color:#666;
}
.player-wrapper {
  position: relative;
  width:100%;
  aspect-ratio: 560/314;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  background: #4d4d50;
}
.album-wrapper{
  width: 100%;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  max-width: 100%;
}
.div_search{
  text-align: center;
  margin: 0 auto;
  background-color: #fff;
  padding: 2em 0;
  width:60%;
  box-sizing: border-box;
}
.chart_flag{
  margin: 1em auto;
  max-height: 56px;
  text-align: center;
  display: block;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.24);
}
.div_eachchart{
  width:400px;
}
.player-wrapper iframe{
  width:100% !important;
  height:100% !important;
}
ul.chart, .focus-section-ul{
  display: flex;
  position: relative;
  overflow: scroll;
  list-style: none;
  margin:0 auto;
  padding: 0;
  margin-bottom: 1em;
}
ul.chart li, .focus-section-ul li{
  position: relative;
  list-style: none;

}
.article-text{
  margin-left:1em;
}
.article-news{
  width:100%;
}
.news-image{
  border-radius: 1em;
  object-fit: cover;
}
.news-image-container{
  display: flex;
  width: 100%;
}
a{
  text-decoration: none;
  color: black ;
}
.load-page{
  text-align: center;
  margin: 3em 0;
}
.news-text{
  display:block;
  font-size:15px;
}
.button_text{
  color:white;
  background-color: grey;
}
#state-map{
  background-color: rgb(250, 240, 230);
  height: auto;
  width: 100%; 
}
.section-title{
  font-size:18px;
  text-align: center;
  display: block;
  margin:1em auto 0.5em auto;
}

.focus-section{
  width:100%;
  margin:1em 0;
  background-color: #efefef;
  border-radius: 3px;
}

.each-focus{
  display:inline-block;
  line-height: 3;
  border: 1px solid #666;
  padding:0 1em;
  margin:0.5em;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 8px;
  background-color: #fff;
}
.each-focus:hover{
  
  font-weight: bold;
}
.focus-section-ul a{
  text-decoration: none;
  color:#666;
}
.focus-section-ul a:hover{
  color:#666;
}

.embed-page .chart_flag{
  max-height:24px;
}
.embed-page .state_name{
  font-size: 1em;
  margin-bottom: -25px;
}
@media only screen and (max-width: 600px) {
  .news-text{
    display: none !important;
  }
  .news-image{
    width: 140px;
    height:90px;
  }
  
}
@media only screen and (min-width:600px){
  .news-image{
    width:250px;
    height:180px;
  }
  .focus-section-ul{
    justify-content: center;
  }
}
@media only screen and (min-width:700px){
  #overall-map{
    height: 350px;
  }
}
@media only screen and (min-width:1000px){
  .home-page{
    width: 70%;
    margin: auto;
  } 
  .div_search{
    width: 70%;
  }
}
@media only screen and (min-width:1300px){
  .home-page{
    width: 60%;
  } 
  .div_search{
    width: 60%;
  }
}
@media only screen and (min-width:1800px){
  .home-page{
    width: 50%;
  } 
  .div_search{
    width: 50%;
  }
  .chart::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
    height: 3px;
  }
  
  .chart::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
}

@media only screen and (max-width: 1200px) {
  
}

@media only screen and (max-width: 999px) {
  .div_search{
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .div_search{
    width: 100%;
  }
  .div_eachchart{
    width:78vw;
  }
}