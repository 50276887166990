.ant-table-wrapper{
    border:1px solid #e5e5e5;
}
.img_win{
    width:50px;
    height:33px;
    float:right;
}
.img_arrow{
    margin:2px 10px;
    width:10px;
    height:19px;
    float:right;
}
.seat_rows{
    vertical-align:baseline !important;
    font-weight:600;
    width:32%;
}
.seat_output{
    vertical-align:baseline !important;
    
}
.table_seat{
    width:100%;
}
.img_list{
    width:11px;
    height:11px;
    margin-right:5px;
}
tr{
    height:50px;
    border-bottom:1px solid grey;
}
.td_separator{
    float:right; margin-right: 1em;
}
@media only screen and (min-width:600px){
    .state_main{
        max-width: 80%;
        margin: auto ;
        justify-content: center;
        text-align: center;
      }
}
@media only screen and (min-width:700px){
    .table_party{
        max-width: 50%;
        margin: auto;
      }
}
@media only screen and (min-width:1000px){
    .state_main{
        max-width: 60%;
    }
}
@media only screen and (min-width:1300px){
    .table_party{
        max-width: 40%;
    }
    .state_main{
        display: flex;
    }
}
