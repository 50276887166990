.odaily-logo{
	background: #cb0c13 url(https://media5.orientaldaily.com.my/assets/images/logo-official.png?d075e3a5eda3d0a9e2fabdca6a33dd05)no-repeat 7px 10px;
	  background-size: 90px 30px;
	  width: 125px;
	  height: 46px;
	  background-position: center;
  }
  .nav-container{
	  display:flex;
  }
  .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after {
	border-bottom: 3px solid #cb0c13 !important;
  }
  
  .ant-menu-horizontal>.ant-menu-item-selected a{
	color:#cb0c13 !important;
  }
  .menu-item{
	  width:100%;
	  height: auto;
	  justify-content: space-between;
  }
  .button-convert{
	  margin: auto;
	  display: flex;
  }
  @media only screen and (min-width:768px){
	.ant-menu-horizontal{
		line-height: 47px !important;
		border-bottom:0 !important;
	  }
	  .odaily-logo{
		width:150px;
		height:50px;
		background-size: 120px 35px;
		background-position: 16px 5px;
	  }
	  .menu-list{
		font-size:17px;
	  }
  }
 