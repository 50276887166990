..player-wrapper {
  position: relative;
  width:100%;
  aspect-ratio: 560/314;
  /* Previous settings */
  /* padding-top: 56.25% ;  */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}