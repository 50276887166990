.state_party1 tr:nth-child(even), .state_party2 tr:nth-child(even){
  background-color: rgb(232,232,232);
}
.state_party1,.state_party2{
  width: 100%;
  margin:0 2em 2em 0;
}
@media only screen and (min-width:1300px){
  .state_party2{
      margin-left:2em;
  }
}